body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f1f1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  width: 95%;
  max-width: 1280px;
  margin: auto;
}

.space {
  margin: 6px;
}

h4 {
  color: #f1f1f1;
  cursor: pointer;
}

.login-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-table-cell-row-hover:hover {
  background-color: #1677ff50 !important;
  cursor: pointer;
}

.delete-button {
  background-color: transparent;
  color: lightcoral;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.details-button {
  background-color: transparent;
  color: midnightblue;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.reprocess-button {
  background: none;
  border: none;
  color: midnightblue;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.button-icon {
  margin-right: 5px;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(3, 3, 3, 0.5);
  color: white;
  padding: 3px 8px;
  border-radius: 5px;
  font-size: 12px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.tooltip-container:hover .tooltip-text {
  opacity: 1;
  visibility: visible;
}
